
const PagejsonLd = 
  {
    "@context": "http://www.schema.org",
    "@type": "Organization",
    "name": "JBcodeapp",
    "url": "https://jbcodeapp.com/",
    "sameAs": [
      "https://www.linkedin.com/in/jbcodeapp",
      "https://twitter.com/jbcodeapp",
      "https://www.facebook.com/jbcodeapp",
      "https://www.instagram.com/jbcodeapp",
      "https://www.youtube.com//@jbcodeapp"
    ],
    "description": "JBcodeapp offers innovative software, web, and mobile app development solutions. Boost your business with our tailored digital services and expert developers.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "536, 5 Square Point,",
      "postOfficeBoxNumber": "RANDER",
      "addressLocality": "Surat",
      "addressRegion": "Gujarat",
      "postalCode": "395005",
      "addressCountry": "India"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "21.229726791381836",
      "longitude": "72.78727722167969"
    },
    "hasMap": "https://maps.app.goo.gl/Z9eH1vt8rjYeyHSz8",
    "openingHours": "Mo 08:00-21:00 Tu 08:00-21:00 We 08:00-21:00 Th 08:00-21:00 Fr 08:00-21:00 Sa 08:00-18:00"
  }
;


const BlogjsonLd = [
  `{
      "@context": "http://www.schema.org",
      "@type": "Organization",
      "name": "JBcodeapp",
      "url": "https://jbcodeapp.com/",
      "logo": "https://jbcodeapp.com/assets/jbcodeapp_logo_white_logo.png",
      "sameAs": [
        "https://www.linkedin.com/in/jbcodeapp",
        "https://twitter.com/jbcodeapp",
        "https://www.facebook.com/jbcodeapp",
        "https://www.instagram.com/jbcodeapp",
        "https://www.youtube.com//@jbcodeapp"
      ],
      "description": "JBcodeapp offers innovative software, web, and mobile app development solutions. Boost your business with our tailored digital services and expert developers.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "536, 5 Square Point,",
        "postOfficeBoxNumber": "RANDER",
        "addressLocality": "Surat",
        "addressRegion": "Gujarat",
        "postalCode": "395005",
        "addressCountry": "India"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "21.229726791381836",
        "longitude": "72.78727722167969"
      },
      "hasMap": "https://maps.app.goo.gl/Z9eH1vt8rjYeyHSz8",
      "openingHours": "Mo 08:00-21:00 Tu 08:00-21:00 We 08:00-21:00 Th 08:00-21:00 Fr 08:00-21:00 Sa 08:00-18:00"
    }`
];

export { PagejsonLd, BlogjsonLd };
